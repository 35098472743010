
import { Component } from "vue-property-decorator";
import MaintenanceItemTypeFormDialog from "@/components/maintenanceSystems/MaintenanceItemTypeFormDialog.vue"
import { BaseMaintenanceItemTypeComponent } from "../base/BaseMixins";
import { MaintenanceItemTypeResource, MaintenanceItemTypeSchemaResource } from "@/resources";
import MaintenanceItemTypeEquipment from "@/components/maintenanceSystems/MaintenanceItemTypeEquipment.vue"
import BaseOptionsMenu from "../base/BaseOptionsMenu.vue";
import InstantUpdateBooleanIconButton from "../forms/InstantUpdateBooleanIconButton.vue";
import MaintenanceItemName from "./MaintenanceItemName.vue";
import { orderBy } from "lodash-es";

// TODO similar to prototype list
// TODO equipment compoentns similar too.

@Component({components : {MaintenanceItemName, InstantUpdateBooleanIconButton, BaseOptionsMenu, MaintenanceItemTypeFormDialog, MaintenanceItemTypeEquipment}})
export default class MaintenanceItemTypeList extends BaseMaintenanceItemTypeComponent {

  formDialog : boolean | null = false
  selectedResource : MaintenanceItemTypeResource | null = null
  selectedResourceSystems : any = []
  selectedResourceAllSchemas : MaintenanceItemTypeSchemaResource[] = []
  expanded : any = []
  deleting : boolean = false
  deleteError : string | null = ""
  headers: any = [
        { text: 'Name', value: 'name', class: "pl-13", sortable: true, width: 300},
        { text: 'Archived', value: 'archived', sortable: false, align:"center", width: "1%"},
        { text: 'Version', value: 'latestVersion', align: 'center', sortable: false, width: "1%"},
        //{ text: 'Components', value: 'components', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false, width: "1%"},
        { text: '', value: 'data-table-expand', width: "1%" },
      ]

  customGroup(items : []) {
    let groups : any = {}
    items.forEach((i:any) => {
      i.maintenanceSystems.forEach((ms:any) => {
        if (!groups[ms.name]) {
          groups[ms.name] = []
        }
        groups[ms.name].push({...i, key: i.id + '-' + ms.id})
      })
    })
    return Object.keys(groups).sort().map(key => ({
      name: key,
      items: groups[key]
    }))
  }

  get items() {
    return this.resources.map(r => ({...r.data(), key: r.data().id, resource: r}))
  }

  sortItems(items: any[]) {
    var sortedItems = orderBy(items, [(value : any) => value.name], "asc")
    return sortedItems
  }

  clickRow(evt : any) {
    if (evt.isEquipment) {
      var idx = this.expanded.findIndex((i:any) => i.id === evt.id)
      if (idx == -1) {
        this.expanded.splice(0)
        this.expanded.push(evt)
      } else {
        this.expanded.splice(idx, 1)
      }
    }
  }

  postInitialLoad() {
    // auto close everything
    // @ts-ignore
    Object.keys(this.$refs.table.openCache).forEach(g => this.$refs.table.openCache[g] = false)
    
  }

  closeFormDialog() {
    this.formDialog = false
    this.selectedResource = null
  }

  async editItem(item : MaintenanceItemTypeResource | null = null) {

    // fetch systems then edit
    this.selectedResourceSystems = []

    if (item) {
      this.selectedResource = item?.fullResource()

      // TODO this isn't updating after editing... caching old systems
      var systemResources = await item.fullResource().maintenanceSystems.getAssociation({}, false)
      this.selectedResourceSystems = systemResources.map((r:any) => r.resource.uri)

      this.selectedResourceAllSchemas = await item.schemaVersions.getAssociation({}, false)

      this.formDialog = true
    }
    else {
      this.selectedResource = null
      this.selectedResourceAllSchemas = []
      this.formDialog = true
    }
  }

  deleteItem(item : MaintenanceItemTypeResource | null = null) {

     // TODO errors
     let idx = this.resources.findIndex(r => r.data().id == item?.data().id)
    if (idx == -1) return;

    // TODO we do this to avoid some weird errors with non existent resources in the list,
    // TODO deal with deletes better 
    var deletedItems = this.resources.splice(idx, 1)

    // TODO errors
    item?.delete().then(() => {
    })
    .catch((err) => {
      if (err?.status === 409) {
        this.resources.splice(idx, 0, deletedItems[0])
        this.deleteError = "Can not delete this item as there is another one dependent upon it.  Please delete that one first then try again. (409)"
      } else {
        this.deleteError = err
      }
    })
    .finally(() => this.deleting = false)
  }

 }
