
import { Component, Watch } from "vue-property-decorator";
import { BaseMaintenanceItemTypeComponent } from "../base/BaseMixins";
import AsyncComputed from "vue-async-computed-decorator";
import { AssociationResource, MaintenanceItemTypeResource } from "@/resources";
import { Debounce } from "vue-debounce-decorator";
import MaintenanceItemName from "./MaintenanceItemName.vue";

@Component({components : {MaintenanceItemName, BaseMaintenanceItemTypeComponent}})
export default class MaintenanceItemTypeEquipment extends BaseMaintenanceItemTypeComponent {
 
  $refs!: {
    typeSearch: any
    partsTable : BaseMaintenanceItemTypeComponent
  }

  addItemsDialog : boolean = false
  adding : boolean = false
  searching : boolean = false
  search : string = ""
  partsErrors : string = ""
  itemsToAdd : any = []
  searchResults : any = []
  deleting : any={}
  expanded : any = []
  headers: any = [
        { text: 'Name', value: 'name', class: "pl-13", sortable: true},
        { text: 'Actions', value: 'actions', sortable: false },
      ]
  apiSearch : AssociationResource<MaintenanceItemTypeResource[]> = new MaintenanceItemTypeResource().searchByName

  @Watch('search')
  @Debounce(300)
  onSearchChanged(newVal : any, oldVal : any) {
    // null on load
    if (newVal != oldVal && this.search != null) {
      this.$asyncComputed.doSearch.update()
    } 
  }

  @Watch('addItemsDialog')
  dialogClosed(newVal : any) {
    // clear cached items if closing dialog
    if (!newVal) {
      this.$refs['typeSearch'].cachedItems = []
    } else {
      // init the default list (load all)
      this.search = ""
      this.$asyncComputed.doSearch.update()
    }
  }

  getSystems(itemType : any) {
    var systems = itemType.maintenanceSystems.map((ms : any) => ms.name)
    return systems.join(" / ")
  }

  @AsyncComputed({shouldUpdate() {return false}}) // FOR DEBOUCE
  async doSearch() {
    this.searching = true
    this.searchResults = await this.apiSearch.getAssociation({name: this.search, projection: "maintenanceItemTypeDetail"}, false)
    
    // TODO see https://github.com/vuetifyjs/vuetify/issues/11365
    this.$refs['typeSearch'].cachedItems = [...this.searchResults, ...this.itemsToAdd]
    
    this.searching = true
  }

  removeItemToAdd(item : any) {
    const index = this.itemsToAdd.findIndex((i : any) => i.resource.uri == item.resource.uri)
    if (index >= 0) this.itemsToAdd.splice(index, 1)
  }

  showAdd() {
    this.addItemsDialog = true
  }

  addItems() {
    var uris = this.itemsToAdd.map((i:any) => i.fullResource().resource.uri)
    this.adding = true
    this.resource.parts.addTo(uris)
    .then(() => {
      this.addItemsDialog = false
      this.itemsToAdd.splice(0)
    })
    .catch((reason) => {
      if (reason && reason.status && reason.status == "409") {
        this.partsErrors = "Part already exists."
      }
    })
    .finally(() => this.adding = false)
  }

  removeItem(item : any) {
    // TODO we do this in other places... make this easier
    this.$set(this.deleting, item.resource.uri, true)
    
    this.resource.parts.detachFrom(item).then(() => {
      // TODO would be good if this were done automatically
      this.$refs.partsTable.update()
    }).finally(() => this.$set(this.deleting, item.resource.uri, false))
  }
 }
