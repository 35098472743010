
import { Component, Prop, Watch } from "vue-property-decorator"
import ValueBasedFormsMixin from "@/components/forms/FormsMixin"
import JsonEditor from "@/components/maintenanceSystems/JsonEditor.vue"
import JsonSchemaControl from "@/components/maintenanceSystems/JsonSchemaControl.vue"
import { BaseMaintenanceSystemComponent } from "../base/BaseMixins"
import { ApiResource, MaintenanceItemTypeResource, MaintenanceSystemsResource } from "@/resources"

@Component({components : {JsonEditor, JsonSchemaControl, BaseMaintenanceSystemComponent}})
export default class MaintenanceItemTypeForm extends ValueBasedFormsMixin {

  @Prop({required: true}) readonly itemTypeSchema!: any
  @Prop() readonly itemType!: MaintenanceItemTypeResource

  customConfigDialog : boolean = false
  getHalFormName() : string { return "maintenanceitemtype" }

  model : object = {}
  tab : any = 0


  @Watch("itemType", {immediate:true})
  schemaChanged() {
    this.tab = 0;
  }


  get maintenanceSystemsAssociation() {
    return ApiResource.Instance.maintenanceSystems
  }

  setSystems(systems: string[]) {
    this.doUpdate('maintenanceSystems', systems)
  }

  /**
   * We only allow setting component/leaf node systems
   * @param resources 
   */
  leafSystemData(resources : MaintenanceSystemsResource[]) {
    return resources
      .filter(r => r.data().isComponent)
      .map(r => ({
      ...r.data(),
      uri : r.uriFull
    }))
  }

  removeSystem(item : any) {
    var idx = this.value.maintenanceSystems.findIndex((ms:any) => ms === item.uri)
    if (idx != -1) {
      this.value.maintenanceSystems.splice(idx, 1)
    }
    this.doUpdate('maintenanceSystems', this.value.maintenanceSystems)
  }

  systemFilter(item : any, queryText : any, itemText : any) {
    const hasValue = (val : any) => val != null ? val : ''

    const text = hasValue(itemText)
    const query = hasValue(queryText)

    return text.toString()
      .toLowerCase()
      .indexOf(query.toString().toLowerCase()) > -1
  }

}
