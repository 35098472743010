
import {Vue, Component, Prop} from 'vue-property-decorator'
import BaseDialog from "@/components/base/BaseDialog.vue"
import { EntityResource } from '@/resources'

@Component({components: {BaseDialog}})
export default class MaintenanceItemsImportDialog extends Vue { 

  @Prop({ required : true }) readonly value!: boolean

  importFile : File | null = null
  importing : boolean = false
  error : any | null = null
  valid : boolean = true

  closeDialog() {
    const theForm : any = this.$refs.form
    theForm.resetValidation()
    this.$emit('input', false)
    this.importing = false
    this.importFile = null
    this.error = null
  }

  importItems() {
    const theForm : any = this.$refs.form
    this.valid = theForm.validate()
    this.importing = true

    let reader = new FileReader();
    reader.readAsText(this.importFile as File);
    reader.onload = () => {

      new EntityResource("admin/import/items").put(reader.result).then(() => {
        this.closeDialog()
        this.$router.go(0)
      }).catch((reason) => {
        this.error = reason
      }).finally(() => {
        this.importing = false
      })

    };
    reader.onerror = () => {
      this.error = reader.error
      this.importing = false
    }

  }

}
