
import {Vue, Component} from 'vue-property-decorator'
import MaintenanceItemTypeList from "@/components/maintenanceSystems/MaintenanceItemTypeList.vue"
import MaintenanceItemPrototypeList from "@/components/maintenanceSystems/MaintenanceItemPrototypeList.vue"
import { ApiResource, EntityResource, MaintenanceItemPrototypeResource, MaintenanceItemTypeResource } from '@/resources'
import BasePanel from '@/components/base/BasePanel.vue'
import BaseOptionsMenu from '@/components/base/BaseOptionsMenu.vue'
import {downloadFile} from "@/util"
import AsyncComputed from 'vue-async-computed-decorator'
import MaintenanceItemsImportDialog from '@/components/maintenanceSystems/MaintenanceItemsImportDialog.vue'
import PropertySearchField from '@/components/properties/PropertySearchField.vue';

@Component({components : {MaintenanceItemsImportDialog, PropertySearchField, BaseOptionsMenu, BasePanel, MaintenanceItemTypeList, MaintenanceItemPrototypeList}})
export default class MaintenanceItemsView extends Vue {

  tab : any | null = null
  importDialog : boolean = false

  @AsyncComputed()
  async hasStuff() {
    var typesCount = parseInt(await (await new MaintenanceItemTypeResource("search/findCount").resource.fetch()).text())
    var prototypesCount = parseInt(await (await new MaintenanceItemPrototypeResource("search/findCount").resource.fetch()).text())
    return typesCount + prototypesCount > 0
  }

  get maintenanceItemTypeAssociation() {
    return ApiResource.Instance.maintenanceItemTypes
  }

  get maintenanceItemPrototypeAssociation() {
    return ApiResource.Instance.maintenanceItemPrototypes
  }

  exportItems() {
    downloadFile(new EntityResource("admin/export/items").resource.uri)
  }
}
