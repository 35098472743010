
import { MaintenanceItemPrototypeResource } from "@/resources";
import { Component, Prop, Vue } from "vue-property-decorator";
import { BaseMaintenanceItemPrototypeComponent } from "../base/BaseMixins";
import MaintenanceItemName from "./MaintenanceItemName.vue";
import MaintenanceItemPrototypeSearch from "./MaintenanceItemPrototypeSearch.vue";

@Component({components : {BaseMaintenanceItemPrototypeComponent, MaintenanceItemName, MaintenanceItemPrototypeSearch}})
export default class MaintenanceItemPrototypeEquipment extends Vue {

  @Prop({required: true}) resource !: MaintenanceItemPrototypeResource

  deleting : any={}
  expanded : any = []
  headers: any = [
        { text: 'Name', value: 'name', class: "pl-13", sortable: true, width: 300},
        { text: 'Type', value: 'type', sortable: false, cellClass: 'text-truncate text-nowrap', align: 'left', width: 100},
        { text: 'Actions', value: 'actions', align: 'center', sortable: false, width: 100 },
      ]

  $refs!: {
    partsTable : BaseMaintenanceItemPrototypeComponent
  }

  removeItem(item : any) {
    // TODO we do this in other places... make this easier
    this.$set(this.deleting, item.resource.uri, true)
    
    this.resource.parts.detachFrom(item).then(() => {
      // TODO would be good if this were done automatically
      this.$refs.partsTable.update()
    }).finally(() => this.$set(this.deleting, item.resource.uri, false))
  }
 }
