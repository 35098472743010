
import {Component, Mixins, Prop} from 'vue-property-decorator'
import BaseResourceFormDialog from "@/components/base/BaseResourceFormDialog.vue"
import MaintenanceItemTypeForm from "@/components/maintenanceSystems/MaintenanceItemTypeForm.vue"
import {ResourceFormDialogMixin} from "@/components/forms/FormsMixin"
import {SettingsResource, MaintenanceItemTypeSchemaResource} from "@/resources"
import {State} from "ketting"
import { BaseMaintenanceItemTypeComponent } from '../base/BaseMixins'

@Component({components: {BaseResourceFormDialog, MaintenanceItemTypeForm}})
export default class MaintenanceItemTypeFormDialog extends Mixins(ResourceFormDialogMixin, BaseMaintenanceItemTypeComponent) { 

  itemTypeSchema : any = null

  @Prop() maintenanceSystemsUriList : any
  @Prop({required: true}) allSchemas !: MaintenanceItemTypeSchemaResource[]

  newModelFunction() : any {

    // updating existing item type ?
    if (this.rdata) {
      const data = {...this.rdata}

      // get latest version
      // TODO / NOTE: itemType.latestVersion should be same as the latest schmea version id, but
      // may not be if as we save types and then schemas... safety measure.

      const latestSchema = this.allSchemas.reduce(
        (prev, current) => {
          return prev.data().schemaVersion > current.data().schemaVersion ? prev : current
        }
      );
      //const latestSchema = this.allSchemas.find((s : any) => s.data().schemaVersion == data.latestVersion)
      
      if (this.allSchemas.length > 0 && !latestSchema) {
        throw Error("Latest schema " + data.latestVersion + " not found for type ID " + data.id + " (# schemas " + this.allSchemas.length + ")")
      }

      if (latestSchema) {
        this.itemTypeSchema = {...latestSchema.data()}
        this.itemTypeSchema.schemaVersion++

        // NOTE: server will ensure to bump latest version of type
        data.latestVersion = this.itemTypeSchema.schemaVersion
      } else {
        this.itemTypeSchema = SettingsResource.defaultObject("maintenanceItemTypeSchema")
      }

      // set systems
      data.maintenanceSystems = [...this.maintenanceSystemsUriList]

      return data
    }
    
    // else new schema
    const data = {
        ...SettingsResource.defaultObject("maintenanceItemType")
    }
    this.itemTypeSchema = SettingsResource.defaultObject("maintenanceItemTypeSchema")
    return data
  }


  /** 
   * We save our schema after saving the type.
   */
  postSaveCallback(state : State<any>) {

    // update schema pointing to parent item type
    this.itemTypeSchema.maintenanceItemType = state.uri
    this.itemTypeSchema.typeId = state.data.id

    // return schema create promise
    return new MaintenanceItemTypeSchemaResource().post(this.itemTypeSchema)
  }

}
