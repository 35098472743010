
import { Component, Prop } from "vue-property-decorator";
import { BaseMaintenanceItemPrototypeComponent, RequiredProjections } from "../base/BaseMixins";
import { ApiResource, AssociationResource, MaintenanceItemPrototypeResource, MaintenanceItemTypeResource, MaintenanceItemTypeSchemaResource, MaintenanceSystemsResource } from "@/resources";
import BaseOptionsMenu from "../base/BaseOptionsMenu.vue";
import { BaseMaintenanceItemTypeComponent } from "../base/BaseMixins";
import InstantUpdateBooleanIconButton from "../forms/InstantUpdateBooleanIconButton.vue";
import MaintenanceItemName from "./MaintenanceItemName.vue";
import MaintenanceItemFormDialog from "./MaintenanceItemFormDialog.vue";
import MaintenanceItemPrototypeEquipment from "./MaintenanceItemPrototypeEquipment.vue";
import {MODE_ADD_EDIT_PROTOTYPE_ITEM} from "./MaintenanceItemFormDialog.vue";

@RequiredProjections("maintenanceItemPrototypeDetail")
@Component({components : {MaintenanceItemPrototypeEquipment, BaseMaintenanceItemTypeComponent, MaintenanceItemName, MaintenanceItemFormDialog, InstantUpdateBooleanIconButton, BaseOptionsMenu}})
export default class MaintenanceItemPrototypeList extends BaseMaintenanceItemPrototypeComponent {

  @Prop({required: true}) type !: "part" | "equipment"

  formDialog : boolean | null = false
  formAttributes : any = {}
  selectedResource : MaintenanceItemPrototypeResource | null = null
  expanded : any = []
  deleting : boolean = false
  addError : string | null = ""
  deleteError : string | null = ""
  search : string = ''
  typeSearch : string = ''
  headers: any = [
        { text: 'Name', value: 'name', class: "pl-13", sortable: true, width: 300, groupable: false},
        { text: 'Type', value: 'typeName', sortable: false, align: 'left', width: 250, filterable: false, groupable: true},
        { text: 'Schema version', value: 'schemaVersion', align: 'center', sortable: false, width: 100, filterable: false, groupable: false},
        { text: '# in service', value: 'inService', align: 'center', sortable: false, width: 100, filterable: false, groupable: false},
        { text: 'Component', value: 'component', sortable: false, filterable: false, groupable: false },
        { text: 'Archived', value: 'archived', sortable: false, align:"center", width:80, filterable: false, groupable: false },
        { text: 'Actions', value: 'actions', sortable: false, width: 80, filterable: false, groupable: false },
        { text: '', value: 'data-table-expand', filterable: false, groupable: false },
      ]

  get typesAssociationResource() {
    return ApiResource.Instance.maintenanceItemTypes
  }

  postInitialLoad() {
    // auto close everything
    // @ts-ignore
    Object.keys(this.$refs.table.openCache).forEach(g => this.$refs.table.openCache[g] = false)
  }

  itemComparator(a: any, b: any) {
    let tIdx = a.typeName.localeCompare(b.typeName)
    if (tIdx != 0) {
      return tIdx
    }
    return a.name.localeCompare(b.name)
  }

  filteredGroupedTypes(resources : MaintenanceItemTypeResource[]) {

    let groupedResources : any = {}
    resources.forEach(r => {

        // group by maintenance system name (may be more than one system)
        r.data().maintenanceSystems.forEach((s:any) => {
          if (!groupedResources[s.name]) {
            groupedResources[s.name] = []
          }
          groupedResources[s.name].push(r)

        })
    })
    // no search, return grouped
    if (!this.typeSearch || !this.typeSearch.trim()) return groupedResources

    let filtered : any = {}
    Object.keys(groupedResources).forEach(key => {
      let types = groupedResources[key].filter((t : MaintenanceItemTypeResource) => t.data().name.toLowerCase().indexOf(this.typeSearch.toLowerCase()) > -1)
      if (types.length > 0) {
        filtered[key] = types
      }
    })
    return filtered
  }


  filterSearch(value: any, search: string | null, item: any) {
    return item.name.toLowerCase().includes(search?.toLowerCase())
  }

  get filteredResourceItems() {
    var filteredResources = (this.type == "equipment" 
        ? this.resources.filter(r => r.data().isEquipment)
        : this.resources.filter(r => !r.data().isEquipment))
    
    let items = filteredResources.map(r => ({...r.data(), resource: r}))

    items.sort(this.itemComparator)

    return items
  }
    

  clickRow(evt : any) {
    if (evt.isEquipment) {
      var idx = this.expanded.findIndex((i:any) => i.id === evt.id)
      if (idx == -1) {
        this.expanded.splice(0)
        this.expanded.push(evt)
      } else {
        this.expanded.splice(idx, 1)
      }
    }
    else {
      this.editItem(evt.resource)
    }
  }

  async addItem2(maintenanceItemType : AssociationResource<MaintenanceItemTypeResource>) {
    let type = await maintenanceItemType.getAssociation({projection: 'maintenanceItemTypeDetail'})
    this.addItem(type)
  }

  async addItem(item : MaintenanceItemTypeResource) {

    const typeId = item.data().id
    const schemaVersion = item.data().latestVersion
    var latestSchemaResource = new MaintenanceItemTypeSchemaResource(typeId + "-" + schemaVersion)
    await latestSchemaResource.get(false)

    // fetch systems
    var systems = await item.maintenanceSystems.getAssociation({}, false)
    var maintenanceSystemUris = systems.map((r : MaintenanceSystemsResource) => r.uriFull)

    const itemType = new MaintenanceItemTypeResource(typeId)
    await itemType.get()

    this.formAttributes = {
      value : true,
      mode : MODE_ADD_EDIT_PROTOTYPE_ITEM,
      maintenanceItemTypeResource : itemType,
      maintenanceSystemUris : maintenanceSystemUris,
      latestSchemaResource : latestSchemaResource,
      currentSchemaResource : latestSchemaResource,
    }

    this.formDialog = true
    
  }

  async editItem(item : MaintenanceItemPrototypeResource) {
    
    // fetch type
    // TODO association issue
    var maintenanceItemTypeResource = await item.maintenanceItemType.getAssociation({}, false)
    maintenanceItemTypeResource = new MaintenanceItemTypeResource(maintenanceItemTypeResource.data().id)
    await maintenanceItemTypeResource.get(false);

    // fetch systems
    var systems = await item.maintenanceSystems.getAssociation({}, false)
    var maintenanceSystemUris = systems.map((r : MaintenanceSystemsResource) => r.uriFull)

    // compose latest schema, fetch current
    const typeId = maintenanceItemTypeResource.data().id
    const schemaVersion = maintenanceItemTypeResource.data().latestVersion
    var latestSchemaResource = new MaintenanceItemTypeSchemaResource(typeId + "-" + schemaVersion)
    await latestSchemaResource.get(false)
    var currentSchemaResource = await item.maintenanceItemTypeSchema.getAssociation({}, false)
    
    this.formAttributes = {
      value : true,
      mode : MODE_ADD_EDIT_PROTOTYPE_ITEM,
      maintenanceItemPrototypeResource : item,
      maintenanceItemTypeResource : maintenanceItemTypeResource,
      maintenanceSystemUris : maintenanceSystemUris,
      latestSchemaResource : latestSchemaResource,
      currentSchemaResource : currentSchemaResource,
    }

    this.formDialog = true

  }

  deleteItem(item : MaintenanceItemPrototypeResource | null = null) {
    // TODO errors
    let idx = this.resources.findIndex(r => r.data().id == item?.data().id)
    if (idx == -1) return

    // TODO we do this to avoid some weird errors with non existent resources in the list,
    // TODO deal with deletes better
    var deletedItems = this.resources.splice(idx, 1)
    item?.delete().then(() => {
    })
    .catch((err) => {
      if (err?.status === 409) {
        this.resources.splice(idx, 0, deletedItems[0])
        this.deleteError = "Can not delete this item as there is another one dependent upon it.  Please delete that one first then try again. (409)"
      } else {
        this.deleteError = err
      }
    })
    .finally(() => this.deleting = false)
  }

 }
